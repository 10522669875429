<template>
  <div>
    <div>
      <div>
        <h2 class="titlePage_subtitle titlePage">Push Pins</h2>
        <Form v-slot="{ errors }" action="" class="form" >
          <div class="row flex-column align-items-center">
            <div class="col-6 p-0">
              <div class="checkboxArea">

                <label class="form__label form__label_row">
                  <input type="checkbox"
                         hidden="hidden"
                         class="form__checkbox"
                         @change="setValues"
                         v-model="selected.settings.top_left"
                  >
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Top</span>
                </label>

                <label class="form__label form__label_row">
                  <input type="checkbox"
                         hidden="hidden"
                         class="form__checkbox"
                         @change="setValues"
                         v-model="selected.settings.top_middle"
                  >
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Top</span>
                </label>

                <label class="form__label form__label_row">
                  <input type="checkbox"
                         hidden="hidden"
                         class="form__checkbox"
                         @change="setValues"
                         v-model="selected.settings.top_right"
                  >
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Top</span>
                </label>

              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-sm-2 col-3 p-0">
                  <div class="checkboxArea checkboxArea_col">
                    <label class="form__label form__label_row">
                      <input type="checkbox"
                             hidden="hidden"
                             class="form__checkbox"
                             @change="setValues"
                             v-model="selected.settings.left_top"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Left</span>
                    </label>

                    <label class="form__label form__label_row">
                      <input type="checkbox"
                             hidden="hidden"
                             class="form__checkbox"
                             @change="setValues"
                             v-model="selected.settings.left_middle"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Left</span>
                    </label>

                    <label class="form__label form__label_row">
                      <input type="checkbox"
                             hidden="hidden"
                             class="form__checkbox"
                             @change="setValues"
                             v-model="selected.settings.left_bottom"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Left</span>
                    </label>
                  </div>
                </div>

                <div class="col-sm-8 col-6 p-0">
                  <div class="bordered">
                  </div>
                </div>

                <div class="col-sm-2 col-3 p-0">
                  <div class="checkboxArea checkboxArea_col">
                    <label class="form__label form__label_row">
                      <input type="checkbox"
                             hidden="hidden"
                             class="form__checkbox"
                             @change="setValues"
                             v-model="selected.settings.right_top"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Right</span>
                    </label>

                    <label class="form__label form__label_row">
                      <input type="checkbox"
                             hidden="hidden"
                             class="form__checkbox"
                             @change="setValues"
                             v-model="selected.settings.right_middle"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Right</span>
                    </label>

                    <label class="form__label form__label_row">
                      <input type="checkbox"
                             hidden="hidden"
                             class="form__checkbox"
                             @change="setValues"
                             v-model="selected.settings.right_bottom"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Right</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 p-0">
              <div class="checkboxArea">
                <label class="form__label form__label_row">
                  <input type="checkbox"
                         hidden="hidden"
                         class="form__checkbox"
                         @change="setValues"
                         v-model="selected.settings.bottom_left"
                  >
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Bottom</span>
                </label>

                <label class="form__label form__label_row">
                  <input type="checkbox"
                         hidden="hidden"
                         class="form__checkbox"
                         @change="setValues"
                         v-model="selected.settings.bottom_middle"
                  >
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Bottom</span>
                </label>

                <label class="form__label form__label_row">
                  <input type="checkbox"
                         hidden="hidden"
                         class="form__checkbox"
                         @change="setValues"
                         v-model="selected.settings.bottom_right"
                  >
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Bottom</span>
                </label>
              </div>
            </div>
          </div>

          <ul>
            <li v-for="group in errors">
              <ul>
                <li
                    class="form__error"
                >{{ group }}
                </li>
              </ul>
            </li>
          </ul>
          <p class="text_center" :class="{'text_red': view_inside}">Please note: Looking from {{view_inside ? 'inside' : 'outside'}}</p>
          <template v-if="actionLinksPortalTarget">
            <portal :to="actionLinksPortalTarget">
              <button type="submit" @click="save"  class="btn btn_transparent"><svg-icon name="solid/check" /> Done</button>
            </portal>
          </template>
          <template v-else>
            <button type="submit" @click="save"  class="btn btn_default"><svg-icon name="solid/check" /> Done</button>
          </template>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>

  import {mapGetters, mapState} from 'vuex';

  export default {
    name: "PushPins",

    data() {
      return {
          selected:{
              components: {},
              settings: {}
          },
            view_inside : ''
      }
    },

    props: ['options', 'checkIsSetPackage', 'actionLinksPortalTarget'],

    methods: {
      save() {
          this.$store.commit('orderPosition/optionNext', 'PSHP');
      },

      closePopup() {
        this.$store.commit('options/closeAvailable');
        this.$store.dispatch('options/showOrderOptions');
      },


        setValues(){
            this.$store.commit('orderPosition/setOption', {option: 'PSHP', selection: this.selected});
        },

    },

    computed: {
      ...mapGetters({
        order: 'createdOrder/createdOrder',
      }),

      ...mapState('options', ['modalView']),

        settings() {
            return this.$store.getters['orderPosition/getCurrentOptionSettings'];
        },

    },
      mounted() {
          this.selected = this.settings;
      },



  }
</script>

<style scoped lang="scss">
  @import '../../../../src/assets/scss/utils/vars';

  .form {

    &__input {
      border-color: $navy;
    }

    .bordered {
      border: 1px solid $navy;
      padding: 10px;
      height: 250px;
    }

    &__labelTitle {
      color: $navy
    }

    .justify-content-center, .justify-content-between {
      display: flex;
      margin: 10px;
    }

    .btn {
      margin: 20px 0;
      margin-bottom: 0;
      max-width: none;
    }
  }

  .modal__close {
    color: $green;
  }
</style>
